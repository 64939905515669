import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase';
import moment from 'moment';
import ReactPixel from 'react-facebook-pixel';

export default function Produtos() {
    const [cores, setCores] = useState('')

    const [empresa, setEmpresa] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [enderecoRodape, setEnderecoRodape] = useState('')

    const [servicos] = useState({
        auth: firebase.auth(),
        pedidos: firebase.firestore().collection('pedidos'),
        configuracao: firebase.firestore().collection('configuracao'),
    })
    const queryParams = new URLSearchParams(window.location.search);
    const [p] = useState(queryParams.get('p'))
    const [i] = useState(queryParams.get('i'))

    const [qrcode, setQrcode] = useState('')
    const [copiaCola, setCopiaCola] = useState('')
    const [status, setStatus] = useState('')
    const [tipoPagamento, setTipoPagamento] = useState('')
    const [valor, setValor] = useState(0)
    const [pedido, setPedido] = useState({})
    const [produto, setProduto] = useState(0)

    // Cronometro
    const [time, setTime] = useState(300);
    const [isActive, setIsActive] = useState(true);
    const [tituloMensagem, setTituloMensagem] = useState('')

    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };

    useEffect(() => {
        if (i) {
            servicos.configuracao.doc('dados').get().then((conf) => {
                setCores(conf.data().cor)
                setEmpresa(conf.data().empresa)
                setCnpj(conf.data().cnpj)
                setEnderecoRodape(conf.data().endereco)
                ReactPixel.init(conf.data().pixelFacebook, options);
                ReactPixel.track('Purchase', { currency: 'R$', value: 'PIX' });
            })
            servicos.pedidos.doc(i).onSnapshot((res) => {
                setStatus(res.data().status)
                setQrcode(res.data().qrcode)
                setCopiaCola(res.data().copiaCola)
                setValor(res.data().valorTotal)
                setTipoPagamento(res.data().tipoPagamento)
                setPedido(res.data())
                setProduto(res.data().produto.produto)
                if (res.data().status === 'CANCELADO') {
                    window.location = '/erro'
                } else if (res.data().status === 'PAGO') {
                    window.location = '/sucesso?i='+res.data().id
                }
            })
        }

    }, [status])

    useEffect(() => {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                setTime((prevTime) => {
                    if (prevTime === 1) {
                        setTituloMensagem('Aguardando seu pagamento..')
                        setIsActive(false)
                        return 0; // Resetar para 5 minutos
                    }
                    return prevTime - 1;
                });
            }, 1000);
        } else if (!isActive && time !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, time,tituloMensagem]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const copyToClipboard = () => {
        const textArea = document.createElement('textarea');

        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';

        textArea.value = copiaCola;

        document.body.appendChild(textArea);
        textArea.select();
        textArea.setSelectionRange(0, 99999); // Para dispositivos móveis

        try {
            document.execCommand('copy');
            mensagemSuceeo('Texto copiado! Cole o código em seu aplicativo de pagamento na área Pix');
        } catch (err) {
            console.error('Erro ao copiar texto: ', err);
        }

        document.body.removeChild(textArea);
    };

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSuceeo(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <>
            <main role="main">
                <div style={{ backgroundColor: '#E6E6E6' }}>
                    <div className="container">
                        <div className="row" style={{ padding: 16 }}>
                            <div className='col-md-12' style={{ marginTop: 30, textAlign: 'center' }}>
                                <h3>Falta pouco! Para finalizar a compra<br /> scaneie o QR Code abaixo</h3>
                            </div>
                            <div className="col-md-6" style={{ marginTop: 50 }}>
                                <div className="card mb-4 p-2" style={{ borderRadius: 8 }}>
                                    <nav style={{ textAlign: 'center', marginTop: 10 }}>
                                        <p>O código expira em: <b style={{ color: 'red' }}>{tituloMensagem ? tituloMensagem : formatTime(time)}</b></p>
                                    </nav>
                                    <hr />
                                    <div className="logo text-center">
                                        <img style={{ width: 200, height: 200, textAlign: 'center' }} src={tipoPagamento === 'MERCADOPAGO' ? "data:image/png;base64, " + qrcode : qrcode} alt="Red dot" />
                                        <hr style={{ width: '50%' }} />
                                        <p style={{ fontSize: 13 }}>Se preferir, pague com a opção PIX Copia e Cola:</p>
                                        <div style={{ width: '100%', alignItems: 'center' }}>
                                            <input value={copiaCola} style={{ fontSize: 13 }} type="text" className="form-control" />
                                            <button onClick={() => copyToClipboard()} style={{ width: '100%', backgroundColor: cores ? cores : '#58ACFA', borderRadius: 8, marginTop: 20, fontSize: 13, fontWeight: 'bold', color: 'white' }} className="btn btn-main">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                                                </svg> COPIAR CÓDIGO
                                            </button>
                                            {tipoPagamento === 'MERCADOPAGO' ?
                                                <p style={{ marginTop: 20, color: 'green' }}>Aguardando seu pagamento...</p>
                                                :
                                                <button onClick={() => copyToClipboard()} style={{ width: '100%', backgroundColor: 'green', borderRadius: 8, marginTop: 10, fontSize: 13, fontWeight: 'bold', color: 'white' }} className="btn btn-main">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                                                    </svg> ENVIAR COMPROVANTE
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{ marginTop: 50 }}>
                                <div style={{ borderRadius: 8 }}>
                                    <nav style={{ marginTop: 10 }}>
                                        <p>Detalhes da compra</p>
                                    </nav>
                                    <hr />
                                    <nav style={{ marginTop: 10 }}>
                                        <p>Valor Total: <b style={{ color: 'green' }}>{pedido.id ? pedido.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : 0}</b></p>
                                        <img style={{ width: 50, height: 50 }} src={pedido.id ? pedido.produto.imagem : 0} />
                                    </nav>
                                    <nav style={{ marginTop: 10 }}>
                                        <p style={{ marginTop: 20, fontSize: 14 }}>Instruções para pagamento</p>
                                        <hr />
                                    </nav>
                                    <nav style={{ marginTop: 10 }}>
                                        <p style={{ marginTop: 10, fontSize: 13, display: 'flex' }}>
                                            <div style={{ marginRight: 10, borderRadius: 50, width: 30, height: 30, backgroundColor: cores ? cores : '#A4A4A4', textAlign: 'center' }}>
                                                <svg style={{ color: 'white', marginTop: 5 }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
                                                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                                                </svg>
                                            </div>

                                            Abra o app do seu banco e entre no ambiente Pix
                                        </p>
                                        <p style={{ marginTop: 10, fontSize: 13, display: 'flex' }}>

                                            <div style={{ marginRight: 10, borderRadius: 50, width: 30, height: 30, backgroundColor: cores ? cores : '#A4A4A4', textAlign: 'center' }}>
                                                <svg style={{ color: 'white', marginTop: 5 }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-qr-code" viewBox="0 0 16 16">
                                                    <path d="M2 2h2v2H2z" />
                                                    <path d="M6 0v6H0V0zM5 1H1v4h4zM4 12H2v2h2z" />
                                                    <path d="M6 10v6H0v-6zm-5 1v4h4v-4zm11-9h2v2h-2z" />
                                                    <path d="M10 0v6h6V0zm5 1v4h-4V1zM8 1V0h1v2H8v2H7V1zm0 5V4h1v2zM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8zm0 0v1H2V8H1v1H0V7h3v1zm10 1h-1V7h1zm-1 0h-1v2h2v-1h-1zm-4 0h2v1h-1v1h-1zm2 3v-1h-1v1h-1v1H9v1h3v-2zm0 0h3v1h-2v1h-1zm-4-1v1h1v-2H7v1z" />
                                                    <path d="M7 12h1v3h4v1H7zm9 2v2h-3v-1h2v-1z" />
                                                </svg>
                                            </div>
                                            <p style={{ marginTop: 5 }}>Escolha <b>Pagar com QrCode</b> e aponte a câmera para o código ao lado</p>
                                        </p>
                                        <p style={{ marginRight: 10, fontSize: 13, display: 'flex' }}>
                                            <div style={{ marginRight: 10, borderRadius: 50, width: 30, height: 30, backgroundColor: cores ? cores : '#A4A4A4', textAlign: 'center' }}>
                                                <svg style={{ color: 'white', marginTop: 6 }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                                </svg>
                                            </div>

                                            <p style={{ marginTop: 5 }}>Confirme as informações e finalize sua compra</p>
                                        </p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="text-muted">
                    <div className="container" style={{ textAlign: 'center', padding: 16 }}>
                        <h6 style={{ fontSize: 14 }}>Formas de pagamento </h6>
                        <img style={{ height: 50, width: 50 }} src='https://pay.growth-resgatar.com/assets/img/card-pix.svg' />
                        <h6 style={{ fontSize: 14, marginTop: 10 }}>{empresa} - {cnpj}</h6>
                        <h6 style={{ fontSize: 14, marginTop: 10 }}>{enderecoRodape}</h6>
                        <h6 style={{ fontSize: 14, marginTop: 20 }}>© {moment().format('YYYY')} Todos os direitos reservados.</h6>
                    </div>
                </footer>
            </main>

        </>

    );
}