import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase';
import moment from 'moment';

export default function Sucesso() {
    const [servicos] = useState({
        auth: firebase.auth(),
        pedidos: firebase.firestore().collection('pedidos'),
        configuracao: firebase.firestore().collection('configuracao'),
    })
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('i'))
    const [pedido, setPedido] = useState({})
    const [empresa, setEmpresa] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [enderecoRodape, setEnderecoRodape] = useState('')
    const [cores, setCores] = useState('')

    useEffect(() => {
        servicos.pedidos.doc(id).get().then((res) => {
            setPedido(res.data())
            servicos.configuracao.doc('dados').get().then((conf) => {
                setCores(conf.data().cor)
                setEmpresa(conf.data().empresa)
                setCnpj(conf.data().cnpj)
                setEnderecoRodape(conf.data().endereco)

                Swal.close()
            })
        })
    }, [])


    function geraPagamento() {

    }
    return (
        <>
            <main role="main">
                <div style={{ backgroundColor: 'white' }}>
                    <div className="container">
                        <div className="row" style={{ padding: 16 }}>
                            <div className='col-md-12' style={{ marginTop: 30, textAlign: 'center' }}>
                                <svg color='green' xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                                <h3>Parabéns<br /> compra realizada com sucesso!</h3>
                            </div>
                            <div className="col-md-12" style={{ marginTop: 50 }}>
                                <div style={{ borderRadius: 8,textAlign:'center' }}>
                                    <nav style={{ marginTop: 10 }}>
                                        <p>Detalhes da compra</p>
                                    </nav>
                                    <hr />
                                    <nav style={{ marginTop: 10 }}>
                                        <p>Valor Total: <b style={{ color: 'green' }}>{pedido.id ? pedido.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : 0}</b></p>
                                        <img style={{ width: 50, height: 50 }} src={pedido.id ? pedido.produto.imagem : 0} />
                                    </nav>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="text-muted">
                    <div className="container" style={{ textAlign: 'center', padding: 16 }}>
                        <h6 style={{ fontSize: 14, marginTop: 10 }}>{empresa} - {cnpj}</h6>
                        <h6 style={{ fontSize: 14, marginTop: 10 }}>{enderecoRodape}</h6>
                        <h6 style={{ fontSize: 14, marginTop: 20 }}>© {moment().format('YYYY')} Todos os direitos reservados.</h6>
                    </div>
                </footer>
            </main>

        </>

    );
}