import React, { useEffect,useState } from 'react';
import Routes from './Router'
import firebase from 'firebase'
import TagManager from 'react-gtm-module'

// const firebaseConfig = {
//   apiKey: "AIzaSyDoR9o0to3LTZyD5kcrixT6PMA2fuBrHkg",
//   authDomain: "popup-a9550.firebaseapp.com",
//   projectId: "popup-a9550",
//   storageBucket: "popup-a9550.appspot.com",
//   messagingSenderId: "1096290638886",
//   appId: "1:1096290638886:web:f67ab39e2cb62a48899ae7"

// };
const firebaseConfig = {
  apiKey: "AIzaSyAdsqd3b6MCUAAD_Nz5MqPnQwlul61SE1M",
  authDomain: "popup-8f0b1.firebaseapp.com",
  projectId: "popup-8f0b1",
  storageBucket: "popup-8f0b1.appspot.com",
  messagingSenderId: "522635522604",
  appId: "1:522635522604:web:f11b5eaf26f71f174e5789"
};
firebase.initializeApp(firebaseConfig);

function App() {
  const [servicos] = useState({
    configuracao: firebase.firestore().collection('configuracao'),
})
  useEffect(() => {
    
    servicos.configuracao.doc('dados').get().then((conf) => {
      const tagManagerArgs = {
        gtmId: conf.data().tagManager
      }
      TagManager.initialize(tagManagerArgs)
    })
  }, [])
  return (
    <div className="App">
      
      <Routes />
    </div>
  );
}

export default App;
