import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase';
import moment from 'moment';
import { criaPreferencia } from '../../services/Checkout'
import { buscaEndereco } from '../../services/Localidades'
import { cpf } from 'cpf-cnpj-validator';
import InputMask from 'react-input-mask';
import ReactPixel from 'react-facebook-pixel';


export default function Produtos() {
    const [token, setToken] = useState('')
    const [banner, setBanner] = useState('')
    const [cores, setCores] = useState('')
    const [logo, setLogo] = useState('')
    const [empresa, setEmpresa] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [enderecoRodape, setEnderecoRodape] = useState('')
    const [tipoLogo, setTipoLogo] = useState('')
    const [redline, setRedline] = useState('')
    const [redlineColor, setRedlineColor] = useState('')

    const [tab, setTab] = useState(0)
    const [checkInfo, setCheckInfo] = useState(false)
    const [checkEntrega, setCheckEntrega] = useState(false)
    const [checkPag, setCheckPag] = useState(false)
    const [width, setWidth] = useState(0)

    const [erroNome, setErroNome] = useState(false)
    const [erroCpf, setErroCpf] = useState(false)
    const [erroTelefone, setErroTelefone] = useState(false)
    const [erroEmail, setErroEmail] = useState(false)

    const [erroCep, setErroCep] = useState(false)
    const [erroEndereco, setErroEndereco] = useState(false)
    const [erroBairro, setErroBairro] = useState(false)
    const [erroNumero, setErroNumero] = useState(false)

    // Fretes
    const [fretes, setFretes] = useState([])
    const [indexFrete, setIndexFrete] = useState(null)
    const [frete, setFrete] = useState({})

    const [servicos] = useState({
        auth: firebase.auth(),
        produtos: firebase.firestore().collection('produtos'),
        pedidos: firebase.firestore().collection('pedidos'),
        configuracao: firebase.firestore().collection('configuracao'),
        frete: firebase.firestore().collection('frete'),
    })
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('c'))

    const [produto, setProduto] = useState({})
    const [quantity, setQuantity] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0);
    const [subtotal, setSUbtotal] = useState(0);
    const [discount, setDiscount] = useState(0)
    const [loading, setLoading] = useState(true)

    const [nome, setNome] = useState('')
    const [telefone, setTelefone] = useState('')
    const [inputCpf, setInputCpf] = useState('')
    const [email, setEmail] = useState('')
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [cidade, setCidade] = useState('')
    const [uf, setUf] = useState('')
    const [complemento, setComplemento] = useState('')

    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };


    const handleIncrease = () => {
        setQuantity(quantity + 1);
    };

    const handleDecrease = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    useEffect(() => {
        mensagemLoading('Carregando seu checkout..')
        servicos.produtos.doc(id).get().then((res) => {
            servicos.configuracao.doc('dados').get().then((conf) => {
                setCores(conf.data().cor)
                setLogo(conf.data().logo)
                setTipoLogo(conf.data().tipoLogo)
                setEmpresa(conf.data().empresa)
                setCnpj(conf.data().cnpj)
                setEnderecoRodape(conf.data().endereco)
                setBanner(conf.data().banner)
                setRedline(conf.data().redline)
                setRedlineColor(conf.data().redlineColor)
                setToken(conf.data().mpAccessToken)

                ReactPixel.init(conf.data().pixelFacebook, options);
                ReactPixel.track('InitiateCheckout', null);
                Swal.close()
                setLoading(false)
            })
            setProduto(res.data())
            if (res.data().desconto > 0) {
                setDiscount(res.data().desconto / 100)
            }
            setTotalPrice(res.data().valor)
        })

    }, [])

    function getPageWidth() {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    useEffect(() => {
        const originalPrice = produto.valor;
        const discountedPrice = originalPrice * (1 - discount);
        setSUbtotal(produto.valor * quantity)
        if (produto.desconto > 0) {
            setTotalPrice((quantity * discountedPrice));
        } else {
            setTotalPrice((produto.valor * quantity));
        }
        let tamanho = getPageWidth()
        setWidth(tamanho)

    }, [quantity, discount, width]);

    function informacoes() {
        setErroNome(false)
        setErroCpf(false)
        setErroTelefone(false)
        setErroEmail(false)
        if (!nome) {
            setErroNome(true)
            return
        }
        if (!cpf.isValid(inputCpf.replace(/[^a-z0-9]/gi, ''))) {
            setErroCpf(true)
            return
        }
        if (telefone.replace(/[^a-z0-9]/gi, '').length < 11) {
            setErroTelefone(true)
            return
        }
        if (!email && !email.includes('@')) {
            setErroEmail(true)
            return
        }
        if (produto.tipoProduto === 'FISICO') {
            setTab(1)
            setCheckInfo(true)
        } else {
            setTab(2)
            setCheckInfo(true)
            setFrete({
                valor: 0
            })
        }

    }

    function entrega() {
        setErroCep(false)
        setErroEndereco(false)
        setErroBairro(false)
        setErroNumero(false)
        if (cep.length < 8) {
            setErroCep(true)
            return
        }
        if (!endereco) {
            setErroEndereco(false)
            return
        }
        if (!bairro) {
            setErroBairro(true)
            return
        }
        if (!numero) {
            setErroNumero(true)
            return
        }
        if (!frete.id) {
            mensagemAlerta('Escolha o frete para prosseguir para pagamento')
            return
        }
        setTab(2)
        setCheckEntrega(true)
    }

    function geraPagamento() {
        if (!nome) {
            mensagemAlerta('Por favor digite seu Nome')
            return
        }
        if (!cpf.isValid(inputCpf.replace(/[^a-z0-9]/gi, ''))) {
            mensagemAlerta('CPF inválido')
            return
        }
        if (!email && !email.includes('@')) {
            mensagemAlerta('Emai inválido')
            return
        }
        if (produto.tipoProduto === 'FISICO') {
            if (!cep) {
                mensagemAlerta('Por favor digite o cep para entrega')
                return
            }
            if (!endereco) {
                mensagemAlerta('Por favor digite o endereço para entrega')
                return
            }
            if (!bairro) {
                mensagemAlerta('Por favor digite o bairro para entrega')
                return
            }
            if (!numero) {
                mensagemAlerta('Por favor digite o número da sua residência para entrega')
                return
            }
            if (!frete.id) {
                mensagemAlerta('Escolha o método de frete')
                return
            }
        }

        mensagemLoading('Gerando seu pix..')
        servicos.pedidos.add({
            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
            dataCheckout: moment().format('YYYY-MM-DD HH:mm'),
            dataPagamento: null,
            cliente: {
                nome: nome,
                cpf: inputCpf,
                email: email
            },
            endereco: {
                cep: cep,
                endereco: endereco,
                bairro: bairro,
                numero: numero,
                cidade: cidade,
                uf: uf,
                complemento: complemento
            },
            frete: frete,
            produto: {
                id: produto.id,
                produto: produto.produto,
                descricao: produto.descricao,
                tipoProduto: produto.tipoProduto,
                imagem: produto.imagem
            },
            tipoPagamento: produto.tipoPagamento,
            valor: parseFloat(produto.valor),
            valorTotal: parseFloat(totalPrice + frete.valor),
            qtd: parseInt(quantity),
            desconto: parseInt(discount),
            status: 'PENDENTE'
        }).then((resP) => {
            servicos.pedidos.doc(resP.id).update({
                id: resP.id
            }).then(() => {
                if (produto.tipoPagamento === 'MERCADOPAGO') {
                    const data = {
                        valor: parseFloat(totalPrice + frete.valor),
                        descricao: produto.descricao,
                        external_reference: resP.id,
                        nome: nome,
                        email: email,
                        cpf: inputCpf.replace(/[^a-z0-9]/gi, ''),
                        token: token
                    }
                    criaPreferencia(data).then((res) => {
                        if (!res.erro) {
                            servicos.pedidos.get().then((p) => {
                                servicos.pedidos.doc(resP.id).update({
                                    qrcode: res.data.pagamento.qrcode,
                                    copiaCola: res.data.pagamento.chave,
                                    preference: res.data.preference,
                                    pedido: parseInt(p.size + 1)
                                }).then(() => {
                                    Swal.close()
                                    window.location = `pagamento?i=${resP.id}`
                                })
                            })
                        }
                    })
                } else {
                    servicos.pedidos.get().then((p) => {
                        servicos.pedidos.doc(resP.id).update({
                            qrcode: produto.qrcode,
                            copiaCola: produto.copiaCola,
                            pedido: parseInt(p.size + 1)
                        }).then(() => {
                            Swal.close()
                            window.location = `pagamento?i=${resP.id}`
                        })
                    })

                }

            })
        })

    }

    function selecionaFrete(index) {
        setIndexFrete(index)
        const originalPrice = produto.valor;
        const discountedPrice = originalPrice * (1 - discount);
        setSUbtotal((originalPrice * quantity) + parseFloat(fretes[index].valor))
        if (produto.desconto > 0) {
            setTotalPrice((quantity * discountedPrice));
        } else {
            setTotalPrice((originalPrice * quantity));
        }
        setFrete(fretes[index])
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setInputCpf(event.target.value);
    }
    const handleTelefone = (event) => {
        event.persist();
        setTelefone(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.length === 8) {
            mensagemLoading('Buscando endereço..')
            buscaEndereco(event.target.value).then((res) => {
                if (!res.erro) {
                    setEndereco(res.logradouro)
                    setBairro(res.bairro)
                    setCidade(res.localidade)
                    setUf(res.uf)
                    servicos.produtos.doc(id).collection('fretes').get().then((resFreteAdd) => {
                        const data = resFreteAdd.docs.map((d) => d.data())
                        setFretes(data)
                        Swal.close()
                    })

                } else {
                    mensagemAlerta('Endereço não localizado, digite os dados manualmente')
                    Swal.close()
                }
            })
        } else {
            setEndereco('')
            setBairro('')
            setCidade('')
            setUf('')
            const originalPrice = produto.valor;
            const discountedPrice = originalPrice * (1 - discount);
            if (produto.desconto > 0) {
                setTotalPrice((quantity * discountedPrice));
            } else {
                setTotalPrice((originalPrice * quantity));
            }
            setFrete({})
            setIndexFrete(null)
            setFretes([])
        }
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBairro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    if (loading) {
        return null
    }

    return (
        <div>
            {getPageWidth() >= 1000 ?
                <main role="main">
                    <div >
                        <img style={{ height: 150, width: 150 }} src={logo ? logo : 'https://i.pinimg.com/736x/13/bc/a1/13bca1e8629eb3de7e4b5cd5fc18d87b.jpg'} />
                    </div>
                    <div style={{ backgroundColor: redlineColor ? redlineColor : '#ef2e4b', padding: 10, textAlign: 'center' }}>
                        <p style={{ color: 'white', marginTop: 10, fontSize: 15 }}>{redline ? redline : 'Parabéns! Agradecemos sua participação na pesquisa da Growth.<br />Aproveite este presente por tempo limitado. Não perca essa oportunidade!'}</p>
                    </div>
                    <div >
                        <img style={{ width: '100%' }} src={banner ? banner : 'https://cloudfox-digital-products.s3.amazonaws.com/uploads/user/AQqyLgqRXW3vkEd/public/stores/4nOzxZoV923J07o/banner/t4JnrCg5C6NBwhtDXm7Y0vVqrSqeI5euCKTGVdEO.jpg'} />
                    </div>

                    <div className="album py-5 bg-light">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="card mb-4 p-2" style={{ borderRadius: 8 }}>
                                        <nav className="nav nav-pills nav-fill">
                                            <a onClick={() => setTab(0)} className={`nav-item nav-link ${tab !== 0 ? 'disabled' : null}`} href="#">
                                                <svg style={{ marginRight: 10 }} color={checkInfo ? '#04B45F' : tab !== 0 ? 'gray' : cores ? cores : '#2E64FE'} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-1-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312z" />
                                                </svg>
                                                Informações
                                            </a>
                                            {produto.tipoProduto === 'FISICO' ?
                                                <a className={`nav-item nav-link ${tab !== 1 ? 'disabled' : null} `} href="#">
                                                    <svg style={{ marginRight: 10 }} color={checkEntrega ? '#04B45F' : tab !== 1 ? 'gray' : cores ? cores : '#2E64FE'} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-2-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z" />
                                                    </svg>
                                                    Entrega
                                                </a>
                                                :
                                                null
                                            }
                                            <a className={`nav-item nav-link ${tab !== 2 ? 'disabled' : null}`} href="#" tabIndex={-1} aria-disabled="true">
                                                <svg style={{ marginRight: 10 }} color={checkPag ? '#04B45F' : tab !== 2 ? 'gray' : cores ? cores : '#2E64FE'} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-3-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z" />
                                                </svg>
                                                Pagamento
                                            </a>
                                        </nav>
                                    </div>
                                    <div className="card mb-4 shadow-sm p-4" style={{ borderRadius: 8 }}>
                                        {tab === 0 ?
                                            <div className='row'>
                                                <div className='col-md-7'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1" style={{ fontSize: 14 }}> Nome Completo</label>
                                                        <input style={{ fontSize: 15, height: 45, borderColor: erroNome ? 'red' : null }} value={nome} onChange={handleNome} type="text" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Nome completo" />
                                                        {erroNome ? <p style={{ fontSize: 10, color: 'red' }}>Digite seu nome completo</p> : null}

                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1" style={{ fontSize: 14 }}>CPF</label>
                                                        <InputMask style={{ fontSize: 15, height: 45, borderColor: erroCpf ? 'red' : null }} maskChar={""} mask="999.999.999-99" placeholder='000.000.000-00' onChange={handleCpf} value={inputCpf} name="username" type="text" className="form-control form-control-lg" />
                                                        {erroCpf ? <p style={{ fontSize: 10, color: 'red' }}>CPF inválido</p> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1" style={{ fontSize: 14 }}>Telefone</label>
                                                        <InputMask style={{ fontSize: 15, height: 45, borderColor: erroTelefone ? 'red' : null }} maskChar={""} mask="(99) 99999-9999" placeholder='(XX) 9XXXX-XXXX' onChange={handleTelefone} value={telefone} name="username" type="text" className="form-control form-control-lg" />
                                                        {erroTelefone ? <p style={{ fontSize: 10, color: 'red' }}>Número de telefone inválido</p> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1" style={{ fontSize: 14 }}>Email</label>
                                                        <input style={{ fontSize: 15, height: 45, borderColor: erroEmail ? 'red' : null }} onChange={handleEmail} value={email} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="email@exemplo.com" />
                                                        {erroEmail ? <p style={{ fontSize: 10, color: 'red' }}>Email inválido</p> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div class="dotted-border">
                                                        <div className="info-segura">
                                                            <div className="about_purchase"><b style={{ fontSize: 14 }}>Usamos seus dados de forma 100% segura para garantir a sua satisfação:</b></div>
                                                            <div className="row nm justify-content-start m-bottom" style={{ marginTop: 10 }}>
                                                                <div className="col-1"><img src="https://pay.growth-resgatar.com/assets/img/checkmarkSecurity.svg" /></div>
                                                                <div className="col-10 pl-md-0">
                                                                    <span className="sub" style={{ fontSize: 14, color: '#A4A4A4' }}>Enviar o seu comprovante de compra e pagamento;</span>
                                                                </div>
                                                            </div>
                                                            <div className="row nm justify-content-start m-bottom">
                                                                <div className="col-1"><img src="https://pay.growth-resgatar.com/assets/img/checkmarkSecurity.svg" /></div>
                                                                <div className="col-10 pl-md-0">
                                                                    <span className="sub" style={{ fontSize: 14, color: '#A4A4A4' }}>Ativar a sua garantia de devolução caso não fique satisfeito;</span>
                                                                </div>
                                                            </div>
                                                            <div className="row nm justify-content-start">
                                                                <div className="col-1"><img src="https://pay.growth-resgatar.com/assets/img/checkmarkSecurity.svg" alt /></div>
                                                                <div className="col-10 pl-md-0">
                                                                    <span className="sub" style={{ fontSize: 14, color: '#A4A4A4' }}>Acompanhar o andamento do seu pedido;</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ textAlign: 'end', marginTop: 30 }}>
                                                    <button onClick={() => informacoes()} type="button" class="btn btn-primary btn-lg" style={{ fontSize: 14, backgroundColor: cores ? cores : null, borderColor: cores ? cores : null }}>{produto.tipoProduto === 'FISICO' ? 'IR PARA ENTREGA' : 'IR PARA PAGAMENTO'}</button>
                                                </div>

                                            </div>
                                            :
                                            null
                                        }
                                        {tab === 1 ?
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">CEP</label>
                                                        <input style={{ fontSize: 15, height: 45, borderColor: erroCep ? 'red' : null }} onChange={handleCep} value={cep} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="00000-000" />
                                                        {erroCep ? <p style={{ fontSize: 10, color: 'red' }}>Cep inválido</p> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">Endereço</label>
                                                        <input style={{ fontSize: 15, height: 45, borderColor: erroEndereco ? 'red' : null }} onChange={handleEndereco} value={endereco} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Rua.. Av.." />
                                                        {erroEndereco ? <p style={{ fontSize: 10, color: 'red' }}>Digite o endereço de entrega</p> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">Bairro</label>
                                                        <input style={{ fontSize: 15, height: 45, borderColor: erroBairro ? 'red' : null }} onChange={handleBairro} value={bairro} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Bairro" />
                                                        {erroBairro ? <p style={{ fontSize: 10, color: 'red' }}>Digite o bairro de entrega</p> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">N</label>
                                                        <input style={{ fontSize: 15, height: 45, borderColor: erroNumero ? 'red' : null }} onChange={handleNumero} value={numero} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="" />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">Cidade</label>
                                                        <input style={{ fontSize: 15, height: 45 }} onChange={handleCidade} value={cidade} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Cidade" />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">UF</label>
                                                        <input style={{ fontSize: 15, height: 45 }} onChange={handleUf} value={uf} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="UF" />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">Complemento</label>
                                                        <input style={{ fontSize: 15, height: 45 }} onChange={handleComplemento} value={complemento} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Complemento" />
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: 20 }}>
                                                    <p style={{ fontSize: 14 }}>Escolha o melhor frete para você</p>
                                                </div>
                                                {fretes.map((d, index) =>
                                                    <div onClick={() => selecionaFrete(index)} className='col-md-12'>
                                                        <div className="card" style={{ borderColor: index === indexFrete ? cores : '#A4A4A4', padding: 10, borderRadius: 10, marginTop: 5 }}>
                                                            <div className='row col-md-12' style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div className='col-md-2' style={{ textAlign: 'center' }}>
                                                                    {index === indexFrete ?
                                                                        <svg style={{ color: cores ? cores : '#A4A4A4' }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                                        </svg>
                                                                        :
                                                                        <svg style={{ color: '#A4A4A4' }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                                        </svg>
                                                                    }

                                                                </div>
                                                                <div className='col-md-7' style={{ marginTop: 10 }}>
                                                                    <h5 style={{ fontSize: 15 }}><b>{d.nome}</b></h5>
                                                                    <p style={{ fontSize: 13, color: '#A4A4A4' }}>{d.descricao}</p>
                                                                </div>
                                                                <div className='col-md-3' style={{ marginBottom: 30, textAlign: 'end' }}>
                                                                    <h6 style={{ fontSize: 15 }}>{d.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h6>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}

                                                <div className='col-md-12'>
                                                    <div class="dotted-border">
                                                        <div className="info-segura">
                                                            <div className="about_purchase"><b style={{ fontSize: 14 }}>Informações de entrega:</b></div>
                                                            <div className="row nm justify-content-start m-bottom" style={{ marginTop: 10 }}>
                                                                <div className="col-1"><img src="https://pay.growth-resgatar.com/assets/img/checkmarkSecurity.svg" /></div>
                                                                <div className="col-10 pl-md-0">
                                                                    <span className="sub" style={{ fontSize: 14, color: '#A4A4A4' }}>Coloque as informações exatas do seu local de entrega;</span>
                                                                </div>
                                                            </div>
                                                            <div className="row nm justify-content-start m-bottom">
                                                                <div className="col-1"><img src="https://pay.growth-resgatar.com/assets/img/checkmarkSecurity.svg" /></div>
                                                                <div className="col-10 pl-md-0">
                                                                    <span className="sub" style={{ fontSize: 14, color: '#A4A4A4' }}>A previsão de entrega pode variar de acordo com a região e facilidade de acesso ao seu endereço;</span>
                                                                </div>
                                                            </div>
                                                            <div className="row nm justify-content-start">
                                                                <div className="col-1"><img src="https://pay.growth-resgatar.com/assets/img/checkmarkSecurity.svg" alt /></div>
                                                                <div className="col-10 pl-md-0">
                                                                    <span className="sub" style={{ fontSize: 14, color: '#A4A4A4' }}>Acompanhar o andamento do seu pedido;</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6' style={{ textAlign: 'start', marginTop: 40 }}>
                                                    <button onClick={() => setTab(0)} type="button" class="btn btn-default btn-lg">Voltar</button>
                                                </div>
                                                <div className='col-md-6' style={{ textAlign: 'end', marginTop: 40 }}>
                                                    <button onClick={() => entrega()} type="button" class="btn btn-primary btn-lg" style={{ fontSize: 14, backgroundColor: cores ? cores : null, borderColor: cores ? cores : null }}>IR PARA PAGAMENTO</button>
                                                </div>

                                            </div>
                                            :
                                            null
                                        }
                                        {tab === 2 ?
                                            <div className='row'>
                                                <div className='col-md-12 mb-4'>
                                                    <h5>Pagamento</h5>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="card" style={{ textAlign: 'center', borderColor: 'blue' }}>
                                                        <img style={{ height: 100, width: 100 }} src='https://www.maxipago.com/developers/wp-content/uploads/2022/02/logo_pix.png' />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12" style={{ marginTop: 30 }}>
                                                    <div className="card p-3" >
                                                        <p>Ao selecionar o Pix, você será encaminhado para um ambiente seguro para finalizar seu pagamento.</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-6' style={{ textAlign: 'start', marginTop: 40 }}>
                                                    <button onClick={() => setTab(produto.tipoProduto === 'FISICO' ? 1 : 0)} type="button" class="btn btn-default btn-lg" style={{ fontSize: 14 }}>Voltar</button>
                                                </div>
                                                <div className='col-md-6' style={{ textAlign: 'end', marginTop: 40 }}>
                                                    <button onClick={() => geraPagamento()} type="button" class="btn btn-success btn-lg" style={{ fontSize: 14 }}>GERAR PIX</button>
                                                </div>

                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                </div>
                                <div className="col-md-1">

                                </div>
                                <div className="col-md-4">
                                    <div className="card mb-4 shadow-sm p-3" style={{ borderRadius: 8 }}>
                                        <h5>Seu carrinho</h5>
                                        <div className='row' style={{ marginTop: 20 }}>
                                            <div className='col-md-2'>
                                                <img style={{ width: 50, height: 50 }} src={produto.imagem} />
                                            </div>
                                            <div className='col-md-7'>
                                                <h6 style={{ fontSize: 14 }}>{produto.produto ? produto.produto.toUpperCase() : ''}</h6>
                                                <p style={{ fontSize: 13, color: '#A4A4A4' }}>PROMOÇÃO APLICADA</p>
                                            </div>
                                            {produto.tipoPagamento !== 'OUTROS' ?
                                                <div className='col-md-3'>
                                                    <div className='dotted-border-round'>
                                                        <button style={{ backgroundColor: 'white', borderWidth: 0, color: 'red', fontSize: 15, borderRadius: 30 }} onClick={handleDecrease}>-</button>
                                                        <span >{quantity}</span>
                                                        <button style={{ backgroundColor: 'white', borderWidth: 0, color: 'green', fontSize: 15, borderRadius: 30 }} onClick={handleIncrease}>+</button>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }

                                        </div>
                                        <hr />
                                        <div className='row' >
                                            <div className='col-md-8'>
                                                <p style={{ fontSize: 12 }}>Produto</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <p style={{ fontSize: 12 }}>{(produto.valor * quantity).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                            </div>
                                        </div>
                                        {produto.desconto ?
                                            <div className='row' >
                                                <div className='col-md-8'>
                                                    <p style={{ fontSize: 12, color: 'green' }}>Desconto de {produto.desconto}%</p>
                                                </div>
                                                <div className='col-md-4'>
                                                    <p style={{ fontSize: 12, color: 'green' }}>{(totalPrice).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        {frete.id ?
                                            <div className='row' >
                                                <div className='col-md-8'>
                                                    <p style={{ fontSize: 12 }}>Frete - {frete.nome}</p>
                                                </div>
                                                <div className='col-md-4'>
                                                    <p style={{ fontSize: 12 }}>{frete.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        <hr />
                                        <div className='row' >
                                            <div className='col-md-8'>
                                                <p>Total</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <p>{(totalPrice + parseFloat(frete.valor ? frete.valor : 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                            </div>
                                        </div>

                                    </div>
                                    {/* <div className='col-md-12 center' style={{ alignItems: 'center' }}>
                                    <div className="card mb-4 shadow-sm p-3" style={{ borderRadius: 30,  }}>

                                    </div>
                                </div> */}

                                    <div className="card mb-4 shadow-sm p-3" style={{ borderRadius: 8 }}>
                                        <div className='row' >
                                            <div className='col-md-2'>
                                                <img style={{ width: 40, height: 40, borderRadius: 50 }} src='https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgGu0QKenhzD9K_RDUy5oSbFwiI9ioLlRGcJtCwZuHE9AhCjtWkCWXt7Jx-n1Dr7OyZ1tEd7OSHSWF4xXLKYBqj_yF7NI5Sq9o0gPBB0TI5mcU3C0uENDkUe8LBq5IYR_Koi9qEctLaaYw/s1600/http:%2F%2F31.media.tumblr.com%2F9ddb113c247d90793781d05b08b03a30%2Ftumblr_n7scbrnxMB1rbbwv5o1_500.jpg' />
                                            </div>
                                            <div className='col-md-6'>
                                                <h6 style={{ fontSize: 14 }}>Fernanda Alves -<br />FORTALEZA</h6>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='row'>
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <p style={{ fontSize: 14 }}>Site excelente com informações precisas e seguras. A navegação é confiável e transparente. Recomendo para quem busca qualidade e seriedade.</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='row' >
                                            <div className='col-md-2'>
                                                <img style={{ width: 40, height: 40, borderRadius: 50 }} src='https://img.freepik.com/fotos-gratis/mulher-desconhecida-posando-na-vista-frontal-do-estudio_23-2149417620.jpg' />
                                            </div>
                                            <div className='col-md-6'>
                                                <h6 style={{ fontSize: 14 }}>Juliana Montezuma -<br />RIO DE JANEIRO</h6>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='row'>
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <p style={{ fontSize: 14 }}>Entrega rápida e eficiente! Recebi o pedido muito antes do esperado e em ótimas condições. Excelente experiência de compra online.</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='row' >
                                            <div className='col-md-2'>
                                                <img style={{ width: 40, height: 40, borderRadius: 50 }} src='https://i.pinimg.com/736x/29/4e/75/294e75328415c8330333e6c82e388cc5.jpg' />
                                            </div>
                                            <div className='col-md-6'>
                                                <h6 style={{ fontSize: 14 }}>Luan Senna -<br />PARANÁ</h6>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='row'>
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <p style={{ fontSize: 14 }}>Recomendo muito este site! A qualidade do serviço é excelente e a experiência foi perfeita. Já indiquei para amigos e familiares.</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='row' >
                                            <div className='col-md-2'>
                                                <img style={{ width: 50, height: 50 }} src='https://plans-reviews.s3.amazonaws.com/uploads/user/AQqyLgqRXW3vkEd/plans-reviews/public/phpN3p97U.png' />
                                            </div>
                                            <div className='col-md-6'>
                                                <h6 style={{ fontSize: 14 }}>Reclame Aqui</h6>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='row'>
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                </div>
                                            </div>
                                            <div className='col-md-12' >
                                                <p style={{ fontSize: 14 }}>54.512 Avaliações Positivas.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                :
                <main role="main">
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10 }}>
                        <img style={{ height: 100, width: 100 }} src={logo ? logo : 'https://i.pinimg.com/736x/13/bc/a1/13bca1e8629eb3de7e4b5cd5fc18d87b.jpg'} />
                        <img style={{ height: 100, width: 100 }} src="https://pay.growth-resgatar.com/assets/img/safe-payment.svg?v=1721244149" alt="" />
                    </div>
                    <div style={{ backgroundColor: redlineColor ? redlineColor : '#ef2e4b', padding: 10, textAlign: 'center' }}>
                        <p style={{ color: 'white', marginTop: 10, fontSize: 15 }}>{redline ? redline : 'Agradecemos sua compra.<br />Aproveite este presente por <b>tempo limitado</b>. Não perca essa oportunidade!'}</p>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <img style={{ width: '100%' }} src={banner ? banner : 'https://cloudfox-digital-products.s3.amazonaws.com/uploads/user/AQqyLgqRXW3vkEd/public/stores/4nOzxZoV923J07o/banner/t4JnrCg5C6NBwhtDXm7Y0vVqrSqeI5euCKTGVdEO.jpg'} />
                    </div>

                    <div className="album py-5 bg-light">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="card mb-4 shadow-sm p-3" style={{ borderRadius: 8 }}>
                                        <h5>Seu carrinho</h5>
                                        <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                                            <img style={{ width: 70, height: 70 }} src={produto.imagem} />
                                            <div className='col-md-10'>
                                                <h6 style={{ fontSize: 14 }}>{produto.produto ? produto.produto.toUpperCase() : ''}</h6>
                                                <p style={{ fontSize: 13, color: '#A4A4A4' }}>PROMOÇÃO APLICADA</p>
                                            </div>
                                            {produto.tipoPagamento !== 'OUTROS' ?
                                                <div >
                                                    <div className='dotted-border-round'>
                                                        <button style={{ backgroundColor: 'white', borderWidth: 0, color: 'red', fontSize: 15, borderRadius: 30 }} onClick={handleDecrease}>-</button>
                                                        <span >{quantity}</span>
                                                        <button style={{ backgroundColor: 'white', borderWidth: 0, color: 'green', fontSize: 15, borderRadius: 30 }} onClick={handleIncrease}>+</button>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }

                                        </div>
                                        <hr />
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                                            <div >
                                                <p style={{ fontSize: 15 }}>- Produto</p>
                                            </div>
                                            <div >
                                                <p style={{ fontSize: 15 }}>{(produto.valor * quantity).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                            </div>
                                        </div>
                                        {produto.desconto ?

                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                                                <div >
                                                    <p style={{ fontSize: 15, color: 'green' }}>- Desconto de {produto.desconto}%</p>
                                                </div>
                                                <div >
                                                    <p style={{ fontSize: 15, color: 'green' }}>{(totalPrice).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        {frete.id ?
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                                                <div >
                                                    <p style={{ fontSize: 15 }}>- Frete - {frete.nome}</p>
                                                </div>
                                                <div >
                                                    <p style={{ fontSize: 15 }}>{frete.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                        <hr />
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                                            <div >
                                                <p style={{ fontSize: 15 }}><b>Total</b></p>
                                            </div>
                                            <div >
                                                <p style={{ fontSize: 15 }}><b>{(totalPrice + parseFloat(frete.valor ? frete.valor : 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card mb-4 p-2" style={{ borderRadius: 8 }}>
                                        <nav style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <svg color={checkInfo ? '#04B45F' : tab !== 0 ? 'gray' : cores ? cores : '#2E64FE'} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-1-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312z" />
                                                </svg>
                                                <a color={tab !== 0 ? 'gray' : '#2E64FE'} onClick={() => setTab(0)} className={`nav-item nav-link ${tab !== 0 ? 'disabled' : null}`} href="#">
                                                    Informações
                                                </a>
                                            </div>

                                            {produto.tipoProduto === 'FISICO' ?
                                                <div style={{ textAlign: 'center' }}>
                                                    <svg color={checkEntrega ? '#04B45F' : tab !== 1 ? 'gray' : cores ? cores : '#2E64FE'} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-2-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z" />
                                                    </svg>
                                                    <a className={`nav-item nav-link ${tab !== 1 ? 'disabled' : null} `} href="#">Entrega</a>
                                                </div>

                                                :
                                                null
                                            }
                                            <div style={{ textAlign: 'center' }}>
                                                <svg color={checkPag ? '#04B45F' : tab !== 2 ? 'gray' : cores ? cores : '#2E64FE'} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-3-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z" />
                                                </svg>
                                                <a className={`nav-item nav-link ${tab !== 2 ? 'disabled' : null}`} href="#" tabIndex={-1} aria-disabled="true">Pagamento</a>
                                            </div>

                                        </nav>
                                    </div>
                                    <div className="card mb-4 shadow-sm p-4" style={{ borderRadius: 8 }}>
                                        {tab === 0 ?
                                            <div className='row'>
                                                <div className='col-md-7'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1" style={{ fontSize: 14 }}> Nome Completo</label>
                                                        <input style={{ fontSize: 15, height: 45, borderColor: erroNome ? 'red' : null }} value={nome} onChange={handleNome} type="text" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Nome completo" />
                                                        {erroNome ? <p style={{ fontSize: 10, color: 'red' }}>Digite seu nome completo</p> : null}

                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1" style={{ fontSize: 14 }}>CPF</label>
                                                        <InputMask style={{ fontSize: 15, height: 45, borderColor: erroCpf ? 'red' : null }} maskChar={""} mask="999.999.999-99" placeholder='000.000.000-00' onChange={handleCpf} value={inputCpf} name="username" type="text" className="form-control form-control-lg" />
                                                        {erroCpf ? <p style={{ fontSize: 10, color: 'red' }}>CPF inválido</p> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1" style={{ fontSize: 14 }}>Telefone</label>
                                                        <InputMask style={{ fontSize: 15, height: 45, borderColor: erroTelefone ? 'red' : null }} maskChar={""} mask="(99) 99999-9999" placeholder='(XX) 9XXXX-XXXX' onChange={handleTelefone} value={telefone} name="username" type="text" className="form-control form-control-lg" />
                                                        {erroTelefone ? <p style={{ fontSize: 10, color: 'red' }}>Número de telefone inválido</p> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1" style={{ fontSize: 14 }}>Email</label>
                                                        <input style={{ fontSize: 15, height: 45, borderColor: erroEmail ? 'red' : null }} onChange={handleEmail} value={email} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="email@exemplo.com" />
                                                        {erroEmail ? <p style={{ fontSize: 10, color: 'red' }}>Email inválido</p> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div class="dotted-border">
                                                        <div className="info-segura">
                                                            <div className="about_purchase"><b style={{ fontSize: 14 }}>Usamos seus dados de forma 100% segura para garantir a sua satisfação:</b></div>
                                                            <div className="row nm justify-content-start m-bottom" style={{ marginTop: 10 }}>
                                                                <div className="col-1"><img src="https://pay.growth-resgatar.com/assets/img/checkmarkSecurity.svg" /></div>
                                                                <div className="col-10 pl-md-0">
                                                                    <span className="sub" style={{ fontSize: 14, color: '#A4A4A4' }}>Enviar o seu comprovante de compra e pagamento;</span>
                                                                </div>
                                                            </div>
                                                            <div className="row nm justify-content-start m-bottom">
                                                                <div className="col-1"><img src="https://pay.growth-resgatar.com/assets/img/checkmarkSecurity.svg" /></div>
                                                                <div className="col-10 pl-md-0">
                                                                    <span className="sub" style={{ fontSize: 14, color: '#A4A4A4' }}>Ativar a sua garantia de devolução caso não fique satisfeito;</span>
                                                                </div>
                                                            </div>
                                                            <div className="row nm justify-content-start">
                                                                <div className="col-1"><img src="https://pay.growth-resgatar.com/assets/img/checkmarkSecurity.svg" alt /></div>
                                                                <div className="col-10 pl-md-0">
                                                                    <span className="sub" style={{ fontSize: 14, color: '#A4A4A4' }}>Acompanhar o andamento do seu pedido;</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: 30 }}>
                                                    <button onClick={() => informacoes()} type="button" class="btn btn-primary btn-lg" style={{ fontSize: 14, width: '100%', backgroundColor: cores ? cores : null, borderColor: cores ? cores : null }}>{produto.tipoProduto === 'FISICO' ? 'IR PARA ENTREGA' : 'IR PARA PAGAMENTO'}</button>
                                                </div>

                                            </div>
                                            :
                                            null
                                        }
                                        {tab === 1 ?
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">CEP</label>
                                                        <input style={{ fontSize: 15, height: 45, borderColor: erroCep ? 'red' : null }} onChange={handleCep} value={cep} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="00000-000" />
                                                        {erroCep ? <p style={{ fontSize: 10, color: 'red' }}>Cep inválido</p> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">Endereço</label>
                                                        <input style={{ fontSize: 15, height: 45, borderColor: erroEndereco ? 'red' : null }} onChange={handleEndereco} value={endereco} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Rua.. Av.." />
                                                        {erroEndereco ? <p style={{ fontSize: 10, color: 'red' }}>Digite o endereço de entrega</p> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">Bairro</label>
                                                        <input style={{ fontSize: 15, height: 45, borderColor: erroBairro ? 'red' : null }} onChange={handleBairro} value={bairro} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Bairro" />
                                                        {erroBairro ? <p style={{ fontSize: 10, color: 'red' }}>Digite o bairro de entrega</p> : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">N</label>
                                                        <input style={{ fontSize: 15, height: 45, borderColor: erroNumero ? 'red' : null }} onChange={handleNumero} value={numero} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="" />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">Cidade</label>
                                                        <input style={{ fontSize: 15, height: 45 }} onChange={handleCidade} value={cidade} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Cidade" />
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">UF</label>
                                                        <input style={{ fontSize: 15, height: 45 }} onChange={handleUf} value={uf} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="UF" />
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlInput1">Complemento</label>
                                                        <input style={{ fontSize: 15, height: 45 }} onChange={handleComplemento} value={complemento} type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Complemento" />
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: 20 }}>
                                                    <p style={{ fontSize: 14 }}>Escolha o melhor frete para você</p>
                                                </div>
                                                {fretes.map((d, index) =>
                                                    <div onClick={() => selecionaFrete(index)} className='col-md-12'>
                                                        <div className="card" style={{ borderColor: index === indexFrete ? cores : '#A4A4A4', padding: 10, borderRadius: 10, marginTop: 5 }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ textAlign: 'center', width: '10%' }}>
                                                                    {index === indexFrete ?
                                                                        <svg style={{ color: cores ? cores : '#A4A4A4' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                                        </svg>
                                                                        :
                                                                        <svg style={{ color: '#A4A4A4' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                                        </svg>
                                                                    }

                                                                </div>
                                                                <div className='col-md-7' style={{ marginTop: 10 }}>
                                                                    <h5 style={{ fontSize: 15 }}><b>{d.nome}</b></h5>
                                                                    <p style={{ fontSize: 13, color: '#A4A4A4' }}>{d.descricao}</p>
                                                                </div>
                                                                <div className='col-md-3' style={{ marginBottom: 30, textAlign: 'end' }}>
                                                                    <h6 style={{ fontSize: 15 }}>{d.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h6>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}


                                                <div className='col-md-12'>
                                                    <div class="dotted-border">
                                                        <div className="info-segura">
                                                            <div className="about_purchase"><b style={{ fontSize: 14 }}>Informações de entrega:</b></div>
                                                            <div className="row nm justify-content-start m-bottom" style={{ marginTop: 10 }}>
                                                                <div className="col-1"><img src="https://pay.growth-resgatar.com/assets/img/checkmarkSecurity.svg" /></div>
                                                                <div className="col-10 pl-md-0">
                                                                    <span className="sub" style={{ fontSize: 14, color: '#A4A4A4' }}>Coloque as informações exatas do seu local de entrega;</span>
                                                                </div>
                                                            </div>
                                                            <div className="row nm justify-content-start m-bottom">
                                                                <div className="col-1"><img src="https://pay.growth-resgatar.com/assets/img/checkmarkSecurity.svg" /></div>
                                                                <div className="col-10 pl-md-0">
                                                                    <span className="sub" style={{ fontSize: 14, color: '#A4A4A4' }}>A previsão de entrega pode variar de acordo com a região e facilidade de acesso ao seu endereço;</span>
                                                                </div>
                                                            </div>
                                                            <div className="row nm justify-content-start">
                                                                <div className="col-1"><img src="https://pay.growth-resgatar.com/assets/img/checkmarkSecurity.svg" alt /></div>
                                                                <div className="col-10 pl-md-0">
                                                                    <span className="sub" style={{ fontSize: 14, color: '#A4A4A4' }}>Acompanhar o andamento do seu pedido;</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: 30 }}>
                                                    <button onClick={() => entrega()} type="button" class="btn btn-primary btn-lg" style={{ fontSize: 14, width: '100%', backgroundColor: cores ? cores : null, borderColor: cores ? cores : null }}>IR PARA PAGAMENTO</button>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: 5 }}>
                                                    <button onClick={() => setTab(0)} type="button" class="btn btn-default btn-lg" style={{ fontSize: 13, width: '100%' }}>Voltar</button>
                                                </div>

                                            </div>
                                            :
                                            null
                                        }
                                        {tab === 2 ?
                                            <div className='row'>
                                                <div className='col-md-12 mb-4'>
                                                    <h5>Pagamento</h5>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="card" style={{ textAlign: 'center', borderColor: cores ? cores : 'blue', width: '30%' }}>
                                                        <img style={{ height: 80, width: 80 }} src='https://www.maxipago.com/developers/wp-content/uploads/2022/02/logo_pix.png' />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12" style={{ marginTop: 30 }}>
                                                    <div className="card p-3" >
                                                        <p>Ao selecionar o Pix, você será encaminhado para um ambiente seguro para finalizar seu pagamento.</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: 40 }}>
                                                    <button onClick={() => geraPagamento()} type="button" class="btn btn-success btn-lg" style={{ fontSize: 14, width: '100%' }}>GERAR PIX</button>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: 5 }}>
                                                    <button onClick={() => setTab(produto.tipoProduto === 'FISICO' ? 1 : 0)} type="button" class="btn btn-default btn-lg" style={{ fontSize: 13, width: '100%' }}>Voltar</button>
                                                </div>

                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                </div>
                                <div className="col-md-4">

                                    <div className="card mb-4 shadow-sm p-3" style={{ borderRadius: 8 }}>
                                        <div className='row' >
                                            <div className='col-md-12' style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: 40, height: 40, borderRadius: 50, marginRight: 10 }}
                                                    src='https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgGu0QKenhzD9K_RDUy5oSbFwiI9ioLlRGcJtCwZuHE9AhCjtWkCWXt7Jx-n1Dr7OyZ1tEd7OSHSWF4xXLKYBqj_yF7NI5Sq9o0gPBB0TI5mcU3C0uENDkUe8LBq5IYR_Koi9qEctLaaYw/s1600/http:%2F%2F31.media.tumblr.com%2F9ddb113c247d90793781d05b08b03a30%2Ftumblr_n7scbrnxMB1rbbwv5o1_500.jpg'
                                                />
                                                <div style={{ fontSize: 14 }}>
                                                    Fernanda Alves <br />FORTALEZA
                                                </div>
                                            </div>

                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <p style={{ fontSize: 14 }}>Site excelente com informações precisas e seguras. A navegação é confiável e transparente. Recomendo para quem busca qualidade e seriedade.</p>
                                            </div>
                                            <div className='col-md-4' >
                                                <div className='row' style={{ marginLeft: 1 }}>
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='row' >
                                            <div className='col-md-12' style={{ display: 'flex', alignItems: 'center' }}>
                                                <img style={{ width: 40, height: 40, borderRadius: 50 }} src='https://img.freepik.com/fotos-gratis/mulher-desconhecida-posando-na-vista-frontal-do-estudio_23-2149417620.jpg' />
                                                <div style={{ fontSize: 14, marginLeft: 10 }}>
                                                    Juliana Montezuma<br />RIO DE JANEIRO
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <p style={{ fontSize: 14 }}>Entrega rápida e eficiente! Recebi o pedido muito antes do esperado e em ótimas condições. Excelente experiência de compra online.</p>
                                            </div>
                                            <div className='col-md-4' >
                                                <div className='row' style={{ marginLeft: 1 }}>
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='row' >
                                            <div className='col-md-12' style={{ display: 'flex', alignItems: 'center' }}>
                                                <img style={{ width: 40, height: 40, borderRadius: 50 }} src='https://i.pinimg.com/736x/29/4e/75/294e75328415c8330333e6c82e388cc5.jpg' />
                                                <div style={{ fontSize: 14, marginLeft: 10 }}>
                                                    Luan Senna<br />PARANÁ
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: 10 }}>
                                                <p style={{ fontSize: 14 }}>Recomendo muito este site! A qualidade do serviço é excelente e a experiência foi perfeita. Já indiquei para amigos e familiares.</p>
                                            </div>
                                            <div className='col-md-4' >
                                                <div className='row' style={{ marginLeft: 1 }}>
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                    <img style={{ height: 20, width: 20 }} src='https://t4.ftcdn.net/jpg/05/40/09/17/360_F_540091788_AvDyNUSbtnKQfNccukuFa3ZlsHFnMYrK.webp' />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            }

            <footer className="text-muted">
                <div className="container" style={{ textAlign: 'center', padding: 16 }}>
                    <h6 style={{ fontSize: 14 }}>Formas de pagamento </h6>
                    <img style={{ height: 50, width: 50 }} src='https://pay.growth-resgatar.com/assets/img/card-pix.svg' />
                    <h6 style={{ fontSize: 14, marginTop: 10 }}>{empresa} - {cnpj}</h6>
                    <h6 style={{ fontSize: 14, marginTop: 10 }}>{enderecoRodape}</h6>
                    <h6 style={{ fontSize: 14, marginTop: 20 }}>© {moment().format('YYYY')} Todos os direitos reservados.</h6>
                </div>
            </footer>
        </div>

    );
}