// Carrega Cidades
export const criaPreferencia = (dados) => {
    const URL = 'https://us-central1-popup-8f0b1.cloudfunctions.net/app/geraPix'
    return fetch(URL, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method:'POST',
        body:JSON.stringify(dados)
    }).then((response) => response.json());
}