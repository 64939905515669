import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'

// Checkout
import Checkout from './pages/Checkout';
import Pagamento from './pages/Pagamento';
import Sucesso from './pages/Sucesso';

// Produtos

export default function Rotas() {
    return (
        <div className="App">
            <BrowserRouter>

                {/* Checkout */}
                <Route path="/" exact={true} component={Checkout} />
                <Route path="/pagamento" exact={true} component={Pagamento} />
                <Route path="/sucesso" exact={true} component={Sucesso} />

            </BrowserRouter>
        </div>
    );
}